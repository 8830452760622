import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faTerminal,
  faLaptopCode,
  faBlog,
  faMapMarkerAlt,
  faLocationArrow,
  faPaperPlane,
  faArrowRight,
  faHandshake,
  faCode,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faTerminal,
  faLaptopCode,
  faBlog,
  faMapMarkerAlt,
  faLocationArrow,
  faPaperPlane,
  faArrowRight,
  faHandshake,
  faCode,
  faAngleRight
)
