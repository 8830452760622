import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { projectsAll } from '../../actions/project'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Navbar, Nav, Image } from 'react-bootstrap'
import logo from '../../assets/logo.svg'
import { practicesAll } from '../../actions/practices'

export const NavBar = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(projectsAll())
    dispatch(practicesAll())
  }, [])

  const animation = () => {
    const logoAnimation = document.querySelector('.logo-menu')
    logoAnimation.classList.add('animate__animated', 'animate__tada')
    setTimeout(() => {
      logoAnimation.classList.remove('animate__animated', 'animate__tada')
    }, 1000)
  }

  return (
    <Navbar
      bg='light'
      expand='lg'
      fixed='top'
      className='border-bottom border-3 border-secondary'>
      <Container>
        <Navbar.Brand as={Link} to='/'>
          <Image
            src={logo}
            fluid
            className='logo-menu'
            alt='Logo Braudin Laya'
            onMouseOver={animation}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Link as={NavLink} to='/projects'>
              <FontAwesomeIcon icon='laptop-code' className='me-2' />
              Proyectos
            </Nav.Link>
            <Nav.Link as={NavLink} to='/practices'>
              <FontAwesomeIcon icon='code' className='me-2' />
              Katas
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to='/publicaciones'>
              <FontAwesomeIcon icon='blog' className='me-2' />
              Publicaciones
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse>
          <Nav className='ms-auto'>
            <Nav.Link as={NavLink} to='/policies'>
              <FontAwesomeIcon icon='handshake' className='me-2' />
              Politicas de trabajo
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
