import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const date = new Date()
  return (
    <div className='footer'>
      © Copyright {date.getFullYear()}
      {' | '}
      <Link to={'/login'}>Ing. Braudin Laya</Link>
    </div>
  )
}
