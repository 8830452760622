import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { loginGoogle, starLogoutGoogle } from '../../actions/auth'

export const Login = () => {
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const auth = state?.auth?.token && true
  const googleLogin = () => {
    !auth ? dispatch(loginGoogle()) : dispatch(starLogoutGoogle())
  }
  const msjError = state.ui.msjError !== null ? state.ui.msjError : ''
  const loading = state.ui.loading

  return (
    <Container className='mb-5 animate__animated animate__fadeIn pt-3'>
      <Row className='justify-content-md-center'>
        <Col xs lg='6'>
          <div className='d-grid gap-2 pt-5 text-center'>
            <Button
              variant='primary'
              type='submit'
              disabled={loading}
              className='float-end'
              onClick={googleLogin}>
              {!auth ? 'Iniciar Sesión' : 'Cerrar Sesión'}
            </Button>
            <p className='msjError'>{msjError}</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
