import { Badge, Button, Container, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Home = () => {
  return (
    <Container className='mb-5 animate__animated animate__fadeIn'>
      <div className='mb-2 p-2 text-center fw-bold text-primary rounded'>
        Desarrollador full-stack freelance
      </div>
      <h1>Braudin Laya</h1>
      <p>Hola, es un gusto que estes aqui.</p>
      <p className='h4 mt-4'>Trabajo</p>
      <p>
        Braudin es un desarrollador full-stack freelance, con experiencia en el
        desarrollo web y móvil. Es una persona que siempre está dispuesta a
        crear productos de calidad, capaces de cumplir con las expectativas y
        los objetivos que se están planteando en el momento, aparte es una
        persona que tiene un alto sentido de responsabilidad, organización y
        detalle (Tanto visual como en la optimización del código y performance).
      </p>
      <p className='h4 mt-4'>Destrezas</p>
      <p>
        Tiene una gran capacidad de adaptación, ya que siempre está en un
        proceso de aprendizaje constante, y siempre está dispuesto a buscar
        nuevos retos, el hecho de que sea una persona sociable le ayuda a
        integrarse muy bien en equipos de trabajos, aunque la realidad es que la
        mayoría de sus trabajos son realizados como un lobo solitario,
        encargándose de todos los procesos de desarrollo.
      </p>
      <p>
        En sus más de 8 años de carrera laboral ha adquirido una gran
        experiencia en diferentes ámbitos del desarrollo de aplicaciones, siendo
        el entorno web el predominante, por lo que se ha enfocado en esta rama
        para procurar cada día ser mejor, son varias habilidades y herramientas
        que ha aprendido a usar para poder lograr los objetivos de sus clientes,
        por lo que solo mencionaremos algunas de ellas como:
      </p>
      <p>
        <Badge bg='warning' text='dark' className='me-2'>
          Html, CSS, Javascript
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Angular, React, React Native
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          PWA, Cordova
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Php, CakePhp, Laravel, NodeJs
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          MySQL, PostgreSQL
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Git, Github, Bitbucket
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Firebase, Api Rest
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Serviidores Linux y Windows
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          cPanel, Plesk
        </Badge>
        <Badge bg='warning' text='dark' className='me-2'>
          Wordpress, Prestashop
        </Badge>
      </p>

      <p className='h4 mt-4'>Biografia</p>
      <dl className='row'>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          1986
        </dt>
        <dd className='col-9'>Nacido en Venezuela - Edo. Apure.</dd>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          2012
        </dt>
        <dd className='col-9'>
          Título universitario de Ingeniería en Sistemas. Universidad Nacional
          Experimental de la Fuerza Armada (UNEFA-VENEZUELA).
        </dd>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          2012 - 2014
        </dt>
        <dd className='col-9'>
          Trabaja como desarrollador full stack en Gobernación del Estado Apure.
        </dd>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          2012 - 2015
        </dt>
        <dd className='col-9'>
          Trabaja como profesor de Matemáticas y Programación en las
          universidades UNEFA, UNERS, Misión Sucre.
        </dd>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          2013 - 2014
        </dt>
        <dd className='col-9'>
          Trabaja como frontend web en UNQUIET PIXEL. Agencia de diseño web de
          España.
        </dd>
        <dt className='col-3'>
          <FontAwesomeIcon icon={['fa', 'arrow-right']} className=' me-2' />
          2014 - HOY
        </dt>
        <dd className='col-9'>
          Trabaja como desarrollador web full stack freelance independiente.
          Creando aplicaciones web y móviles, para distintos clientes en varios
          países.
        </dd>
      </dl>
      <p className='h4 mt-4'>Pasatiempos</p>
      <p>
        Le gusta mucho escuchar música, la lectura, los videojuegos, las
        computadoras, tocar percusión, pero sobre todo pasar tiempo de calidad
        en paseos con su familia.
      </p>
      <p className='h4 mt-4'>Creencias</p>
      <p>
        Es una persona de fe cristiana, testifica y anuncia que Jesús es el hijo
        de Dios y el salvador de la humanidad. Tiene la biblia como su manual de
        vida, resaltando valores como el amor, la integridad, la
        responsabilidad, entre otros.
      </p>
      <p>
        Tiene dos frases que han marcado su historia{' '}
        <span className='fw-bold'>
          &quot;Disfruta donde estás camino a donde vas&quot;
        </span>{' '}
        y{' '}
        <span className='fw-bold'>
          &quot;Siempre hay uno mejor que yo&quot;
        </span>
        . Enfatizando que una enseña a vivir la vida y la otra a ser humilde.
      </p>
      <p>
        Todo su trabajo siempre está enmarcado en el texto bíblico
        <cite>
          &quot; Y todo lo que hagáis, hacedlo de corazón, como para el Señor y
          no para los hombres; sabiendo que del Señor recibiréis la recompensa
          de la herencia, porque a Cristo el Señor servís&quot;.
          <br />
          Colosences 3:23-24
        </cite>
      </p>
      <p className='h4 mt-4'>Contacto</p>
      <Nav className='flex-column'>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='https://api.whatsapp.com/send?phone=584129267771'>
            <FontAwesomeIcon icon={['fab', 'whatsapp']} className=' me-2' />
            +58 412 926 77 71
          </Button>
        </Nav.Item>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='mailto:freelance@braudin.com'>
            <FontAwesomeIcon icon={['fa', 'paper-plane']} className=' me-2' />
            freelance@braudin.com
          </Button>
        </Nav.Item>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='https://www.facebook.com/braudinlaya/'>
            <FontAwesomeIcon icon={['fab', 'facebook']} className=' me-2' />
            @braudinlaya
          </Button>
        </Nav.Item>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='https://twitter.com/BraudinLaya'>
            <FontAwesomeIcon icon={['fab', 'twitter']} className=' me-2' />
            @BraudinLaya
          </Button>
        </Nav.Item>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='https://github.com/Braudin'>
            <FontAwesomeIcon icon={['fab', 'github']} className=' me-2' />
            @Braudin
          </Button>
        </Nav.Item>
        <Nav.Item as='li'>
          <Button
            variant='secondary'
            target='_blank'
            href='https://www.instagram.com/braudinfre/?hl=es-la'>
            <FontAwesomeIcon icon={['fab', 'instagram']} className=' me-2' />
            @Braudin
          </Button>
        </Nav.Item>
      </Nav>
    </Container>
  )
}
