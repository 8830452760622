import { types } from '../types/types'

const int = {
  msjError: null,
  loading: false,
}
export const uiReduce = (state = int, action) => {
  switch (action.type) {
    case types.setError:
      return {
        ...state,
        msjError: action.payload,
      }

    case types.removeError:
      return {
        ...state,
        msjError: null,
      }

    case types.startLoading:
      return {
        ...state,
        loading: true,
      }

    case types.finishLoading:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
