import { types } from '../types/types'

export const practicesReduce = (state = [], action) => {
  switch (action.type) {
    case types.practices:
      return action.payload

    default:
      return state
  }
}
