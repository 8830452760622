import { Container } from 'react-bootstrap'

export const PoliciesWork = () => {
  return (
    <Container className='mb-5 animate__animated animate__fadeIn pt-3'>
      <p>
        En mi trayectoria laboral como Freelancer he tenido experiencias gratas,
        con buenos clientes, a los que agradezco su confianza, respeto y
        compromiso; pero también he tenido experiencias no muy gratas; de manera
        que la falta de compromiso y honestidad por parte de algunos clientes,
        me ha llevado a establecer las siguientes políticas de trabajo:
      </p>
      <h5 className='mt-4'>Forma de pago</h5>
      <p>
        Pagos menores a $1500: Estos proyectos se cobran 50% antes de iniciar,
        40% al enviar a revisión y 10% en la entrega final.
      </p>
      <p>
        Pagos mayores a $1501: Estos proyectos se cobran 30% antes de arrancar,
        y el 70% restante se cancelará en mutuo acuerdo y conveniencia con el
        cliente.
      </p>
      <p>
        Contratos: Para contratos los pagos serán en la modalidad de quincenas,
        pagando el 50% del acuerdo mensual los días 15 y 30 de cada mes.
      </p>
      <h5 className='mt-4'>Comunicación</h5>
      <p>
        La comunicación: deberá ser una acción imprescindible, basada en el
        respeto y fluidez en todo momento, para evitar atrasos en la fecha
        pautada para la entrega del proyecto o malos entendidos en las ideas. Si
        alguna de las partes se ausentará por un tiempo, deberá informarlo
        anticipadamente.
      </p>
      <p>
        Si el cliente se ausenta en un lapso de 72 horas (3 días) continuas
        causara una pausa momentánea en el desarrollo de su proyecto, pudiendo
        afectar la fecha de entrega pautada.
      </p>
      <p>
        Si el cliente se ausenta en un lapso de 168 horas (7 días) continuas el
        proyecto entrara en etapa de pausa permanente; lo que implica, que el
        proyecto se retomará solo cuando el cliente retome la comunicación y se
        establecerá una nueva fecha para la entrega del proyecto.
      </p>
      <p>
        Si el cliente se ausenta en un lapso de 1080 horas (45 días) continuas,
        el proyecto quedará descartado por lo que mi persona no se hará
        responsable del mismo, el proyecto permanecerá en el repositorio por un
        periodo de tiempo de hasta 3 meses; En caso de que el cliente quiera
        retomar la continuidad del mismo, se realizara un reajusté de tiempo y
        costos.
      </p>
      <h5 className='mt-4'>Revisiones</h5>
      <p>
        Una revisión consiste en poner en manos del cliente, el proyecto ya casi
        culminado, para que pueda verificar el Diseño, Funcionalidad, etc. El
        cliente debe notificar en detalle, los aspectos a corregir. Si en medio
        del proceso de desarrollo del proyecto, el cliente detecta algún error
        debe indicarlo con anterioridad, para evitar ser incluido en las
        correcciones.
      </p>
      <p>
        En proyectos de desarrollo Web, las revisiones se realizarán a medida
        que se va completando las fases o funcionalidades del proyecto.
      </p>
      <p>
        En los demás proyectos, las revisiones se llevarán a cabo solo al
        terminar la fase de diseño o desarrollo, y cada cliente tendrá tres (03)
        revisiones sin costo adicional.
      </p>
      <h5 className='mt-4'>Derechos</h5>
      <p>
        Una vez cancelado la totalidad del proyecto el cliente tiene derecho
        para editar, parcial o totalmente el código fuente o diseño del
        proyecto.
      </p>
      <p>
        Con previa autorización del cliente, su proyecto será exhibido en mi
        portafolio Web, como referencia para otros clientes.
      </p>
      <p>
        Con previa autorización del cliente, en su proyecto Web se dejará un
        enlace a mi portafolio Web con el texto, &quot;Diseñado y desarrollado
        por: Braudin.com&quot;.
      </p>
    </Container>
  )
}
