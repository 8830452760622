import { types } from '../types/types'

export const authReduce = (state = null, action) => {
  switch (action.type) {
    case types.login:
      return {
        token: action.payload.token,
        user: action.payload.user,
      }

    case types.logout:
      return null

    default:
      return state
  }
}
