import { Container, Button, Row, Col, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
/* import Gist from 'react-gist' */
import { Link } from 'react-router-dom'

export const Practices = () => {
  const state = useSelector((state) => state)
  const practices = state.practices
  const auth = state?.auth?.token && true

  return (
    <Container className='mb-5 animate__animated animate__fadeIn'>
      <Row>
        {practices.map((practice) => (
          <Col lg='12' key={practice.id}>
            <Link
              to={`/practices/${practice.id}`}
              className='btn btn-secondary text-start p-2'>
              <h3>{practice.name} </h3>
              <p>
                {practice.badges.map((badge) => (
                  <Badge bg='warning' text='dark' className='me-2' key={badge}>
                    {badge}
                  </Badge>
                ))}
              </p>
              <p>{practice.description.substring(0, 120) + ' ...'}</p>
            </Link>
          </Col>
        ))}
        {auth && (
          <Button
            as={Link}
            className='mt-4'
            variant='primary'
            to='/admin/practices-ae'>
            Agregar un nuevo proyecto
          </Button>
        )}
      </Row>
    </Container>
  )
  /* return (
    <Container className='mb-5 animate__animated animate__fadeIn pt-3'>
      <p>
        Eres un reclutador o simplemente estás revisando mi perfil profesional y
        quieres evaluar mi forma de programar y resolver problemas, te dejo las
        plataformas que suelo usar para entrenar, resolviendo distintos retos o
        katas plantiadas por la comunidad o plataforma.
      </p>
      <p>
        <Button
          variant='primary'
          size='sm'
          href='https://app.codesignal.com/profile/braudin'
          target='_black'>
          CodeSignal
        </Button>
        <Button
          variant='primary'
          size='sm'
          href='https://www.codewars.com/users/braudin'
          target='_black'
          className='ms-3'>
          CodeWars
        </Button>
        {auth && (
          <Button
            as={Link}
            className='ms-3'
            variant='primary'
            size='sm'
            to='/admin/practices-ae'>
            Nueva Kata
          </Button>
        )}
      </p>
      <hr />
      {practices.map((item, index) => (
        <div className='ms-2 me-auto' key={'gist-' + index}>
          <div className='fw-bold'>{item.title}</div>
          <small>
            {item.platform} | {item.language}
          </small>
          <p className='mt-3 my-0'>{item.description}</p>
          <Gist id='bcdbfdfdbf6da8aadf64cdb90e015ccc' />
          <hr />
        </div>
      ))}
    </Container>
  ) */
}
