import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD3yGQQb_8uNlxKiY2L7CoW-5OdqE3eADs',
  authDomain: 'web-braudin-8477c.firebaseapp.com',
  projectId: 'web-braudin-8477c',
  storageBucket: 'web-braudin-8477c.appspot.com',
  messagingSenderId: '854118865842',
  appId: '1:854118865842:web:6fe8e2a1703dd2ef5de2ab',
  measurementId: 'G-5JCFE7BX3N',
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const st = getStorage(app)
export const auth = getAuth()
export const providerGoogle = new GoogleAuthProvider()
