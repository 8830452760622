import { Badge, Breadcrumb, Container, Row, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Gist from 'react-gist'
import { Link, useParams } from 'react-router-dom'

export const PracticesDetails = () => {
  const { id } = useParams()
  const state = useSelector((state) => state)
  const practice = state.practices?.find((pract) => pract?.id === id)
  const auth = state?.auth?.token && true

  return (
    <Container className='mb-5 animate__animated animate__fadeIn my-2'>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/practices' }}>
          Katas
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{practice?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h3>{practice?.name}</h3>
      <p>
        {practice?.badges?.map((badge) => (
          <Badge bg='warning' text='dark' className='me-2' key={badge}>
            {badge}
          </Badge>
        ))}
      </p>
      <p>{practice?.description}</p>
      {practice?.gistid && <Gist id={practice?.gistid} />}
      {auth && (
        <Row>
          <Button
            as={Link}
            className='mt-4'
            variant='primary'
            to={`/admin/practices-ae?id=${practice?.id}&edit=true`}>
            Editar Kata
          </Button>
        </Row>
      )}
    </Container>
  )
}
