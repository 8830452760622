import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'

export const ProjectSingleList = ({ project }) => {
  return (
    <Link
      to={`/projects/${project.id}`}
      className='btn btn-secondary text-start p-2'>
      <h3>{project.name} </h3>
      <p>
        {project.badges.map((badge) => (
          <Badge bg='warning' text='dark' className='me-2' key={badge}>
            {badge}
          </Badge>
        ))}
      </p>
      <p>{project.resume.substring(0, 120) + ' ...'}</p>
    </Link>
  )
}

ProjectSingleList.propTypes = {
  project: PropTypes.object.isRequired,
}
