import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { ProjectSingleList } from './ProjectSingleList'

export const ProjectsList = () => {
  const state = useSelector((state) => state)
  const projects = state?.projects
  const auth = state?.auth?.token && true
  return (
    <Container className='mb-5 animate__animated animate__fadeIn'>
      <Row>
        {projects.map((project) => (
          <Col lg='12' key={project.id}>
            <ProjectSingleList project={project} />
          </Col>
        ))}
        {auth && (
          <Button
            as={Link}
            className='mt-4'
            variant='primary'
            to='/admin/projects-ae'>
            Agregar un nuevo proyecto
          </Button>
        )}
      </Row>
    </Container>
  )
}
