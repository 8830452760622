import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { authReduce } from '../reducers/authReduce'
import { practicesReduce } from '../reducers/practicesReduce'
import { projectsReduce } from '../reducers/projectsReduce'
import { uiReduce } from '../reducers/uiReduce'

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const reducers = combineReducers({
  projects: projectsReduce,
  practices: practicesReduce,
  auth: authReduce,
  ui: uiReduce,
})

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
)
