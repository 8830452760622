import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { handleChange } from '../../helps/help'
import { practicesAdd } from '../../../actions/practices'

const int = {
  title: '',
  platform: '',
  language: '',
  badges: '',
  gistid: '',
}

export const PracticesAdd = () => {
  const [searchParams] = useSearchParams()
  const state = useSelector((state) => state)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const practice = id ? state?.practices.find((pra) => pra.id === id) : int
  const [form, setform] = useState({
    ...practice,
    badges: practice.badges.toString(),
  })
  const dispatch = useDispatch()

  const loading = state.ui.loading

  const sendForm = (e) => {
    e.preventDefault()
    const noVacio =
      form.name.length !== 0 &&
      form.badges.length !== 0 &&
      form.description.length !== 0 &&
      form.gistid.length !== 0
    const badges = form.badges
      .split(',')
      .map((item) => item.trim().toLowerCase())

    noVacio
      ? dispatch(practicesAdd({ ...form, badges }, edit)).then((r) => alert(r))
      : alert('Error en los datos')
  }

  return (
    <Form onSubmit={sendForm}>
      <Container className='mb-5 animate__animated animate__fadeIn pt-3'>
        <Row className='justify-content-md-center'>
          <Col>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='name'
                value={form.name}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Titulo de la Kata'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='badges'
                value={form.badges}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Plataforma'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='description'
                value={form.description}
                onChange={(event) => handleChange(event, form, setform)}
                as='textarea'
                rows={10}
                placeholder='Descripcion'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='gistid'
                value={form.gistid}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Gist'
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col>
            <Button
              variant='primary'
              type='submit'
              disabled={loading}
              className='float-end'>
              {'Agregar Kata'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}
