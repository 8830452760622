import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { types } from '../types/types'

export const practicesAll = () => {
  return async (dispatch) => {
    let practices = []
    const querySnapshot = await getDocs(collection(db, 'practices'))
    querySnapshot.forEach((doc) => {
      const t = { id: doc.id, ...doc.data() }
      practices = [...practices, t]
    })
    dispatch(setPractices(practices))
  }
}

export const practicesAdd = (practice, edit = false) => {
  return async (dispatch) => {
    const id = practice.id
    delete practice.id
    if (!edit) {
      await addDoc(collection(db, 'practices'), practice)
    } else {
      await updateDoc(doc(db, 'practices', id), practice)
    }
    return 'Datos guardados'
  }
}

export const setPractices = (practices) => {
  return {
    type: types.practices,
    payload: practices,
  }
}
