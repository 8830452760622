import { useLocation } from 'react-router-dom'
import { Col, Container, Image, Nav, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import bl from '../../assets/img/Braudin-Laya-Sobre-mi.png'

export const Braudin = () => {
  const { pathname } = useLocation()
  const pg = pathname.slice(1).split('/')
  let titlePage = ''
  if (pg.includes('')) titlePage = 'sobre mí'
  if (pg.includes('projects') || pg.includes('projects-ae'))
    titlePage = 'Proyectos'
  if (pg.includes('policies')) titlePage = 'Políticas'
  if (pg.includes('login')) titlePage = 'Login'
  if (pg.includes('practices') || pg.includes('practices-ae'))
    titlePage = 'Katas'
  return (
    <Container className='pt-5 mt-5 text-center'>
      <Row className='justify-content-center'>
        <Col xs={6} md={4} lg={3}>
          <Image
            src={bl}
            fluid
            className='rounded-circle border border-5 border-primary'
          />
          <div className='page'>{titlePage}</div>
        </Col>
      </Row>
      <Nav className='justify-content-center'>
        <Nav.Item as='li'>
          <Nav.Link
            target='_blank'
            href='https://api.whatsapp.com/send?phone=584129267771'>
            <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as='li'>
          <Nav.Link target='_blank' href='https://twitter.com/BraudinLaya'>
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as='li'>
          <Nav.Link target='_blank' href='https://github.com/Braudin'>
            <FontAwesomeIcon icon={['fab', 'github']} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as='li'>
          <Nav.Link href='mailto:freelance@braudin.com'>
            <FontAwesomeIcon icon={['fa', 'paper-plane']} />
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <hr className='m-0' />
    </Container>
  )
}
