export const types = {
  projects: '[projects] getAll',
  practices: '[practices] getAll',
  login: '[auth] login',
  logout: '[auth] logout',
  setError: '[ui] setError',
  removeError: '[ui] removeError',
  startLoading: '[ui] startLoading',
  finishLoading: '[ui] finishLoading',
  msj: '[ui] smj',
}
