import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { types } from '../types/types'

export const projectsAll = () => {
  return async (dispatch) => {
    let projects = []
    const querySnapshot = await getDocs(collection(db, 'projects'))
    querySnapshot.forEach((doc) => {
      const t = { id: doc.id, ...doc.data() }
      projects = [...projects, t]
    })
    dispatch(setProyectos(projects))
  }
}

export const projectsAdd = (project, edit = false) => {
  return async (dispatch) => {
    const id = project.id
    delete project.id
    if (!edit) {
      await addDoc(collection(db, 'projects'), project)
    } else {
      await updateDoc(doc(db, 'projects', id), project)
    }
    return 'Datos guardados'
  }
}

export const setProyectos = (projects) => {
  return {
    type: types.projects,
    payload: projects,
  }
}
