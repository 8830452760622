import { signInWithPopup, deleteUser, GoogleAuthProvider } from 'firebase/auth'
import { auth, providerGoogle } from '../firebase'
import { types } from '../types/types'
import { finishLoading, removeError, setError, startLoading } from './ui'

export const login = (token, user) => ({
  type: types.login,
  payload: {
    token,
    user,
  },
})

export const loginGoogle = () => {
  return (dispatch) => {
    dispatch(startLoading())
    signInWithPopup(auth, providerGoogle)
      .then((result) => {
        dispatch(finishLoading())
        const credential = GoogleAuthProvider.credentialFromResult(result)
        result._tokenResponse.isNewUser
          ? dispatch(deleteUserGoogle(auth.currentUser))
          : dispatch(login(credential.accessToken, result.user))
      })
      .catch((error) => {
        dispatch(finishLoading())
        console.log(error)
      })
  }
}

export const deleteUserGoogle = (user) => {
  return (dispatch) => {
    deleteUser(user).catch((error) => console.log(error))
    dispatch(setError('Usuario invalido'))
    setTimeout(() => {
      dispatch(removeError())
    }, 3000)
  }
}

export const starLogoutGoogle = () => {
  return async (dispatch) => {
    await auth.signOut()
    dispatch(logout())
  }
}

const logout = () => ({
  type: types.logout,
})
