import React, { useState, useRef } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { handleChange } from '../../helps/help'
import { projectsAdd } from '../../../actions/project'
import { useSearchParams } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { st } from '../../../firebase'
const int = {
  name: '',
  badges: '',
  resume: '',
  description: '',
  urlApp: '',
  urlClient: '',
  images: [],
}

export const ProyectoAdd = () => {
  const [searchParams] = useSearchParams()
  const state = useSelector((state) => state)
  const inputFile = useRef(null)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const project = id ? state?.projects.find((pro) => pro.id === id) : int
  const [form, setform] = useState({
    ...project,
    badges: project.badges.toString(),
  })
  const dispatch = useDispatch()

  const loading = state.ui.loading

  const sendForm = (e) => {
    e.preventDefault()
    const noVacio =
      form.name.length !== 0 &&
      form.badges.length !== 0 &&
      form.resume.length !== 0 &&
      form.description.length !== 0 &&
      form.urlApp.length !== 0 &&
      form.urlClient.length !== 0 &&
      form.images.length !== 0

    const badges = form.badges
      .split(',')
      .map((item) => item.trim().toLowerCase())

    const data = { ...form, badges }
    noVacio
      ? dispatch(projectsAdd(data, edit)).then((r) => alert(r))
      : alert('Error en los datos')
  }

  const uploadImg = (e) => {
    const storageRef = ref(
      st,
      `projects/${Date.now() + e.target.files[0].name}`
    )
    uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
      getDownloadURL(ref(st, snapshot.metadata.fullPath)).then((url) => {
        const imgs = [...form.images, url]
        setform({ ...form, images: imgs })
      })
    })
  }

  /*   const uploadFile = () => {} */

  return (
    <Form onSubmit={sendForm}>
      <Container className='mb-5 animate__animated animate__fadeIn pt-3'>
        <Row className='justify-content-md-center'>
          <Col>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='name'
                value={form.name}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Nombre del proyecto'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='urlClient'
                value={form.urlClient}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Url Cliente'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='urlApp'
                value={form.urlApp}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Url App'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='badges'
                value={form.badges}
                onChange={(event) => handleChange(event, form, setform)}
                placeholder='Etiquetas (Separar por ,)'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='resume'
                value={form.resume}
                onChange={(event) => handleChange(event, form, setform)}
                as='textarea'
                rows={2}
                placeholder='Resumen'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='titulo'>
              <Form.Control
                name='description'
                value={form.description}
                onChange={(event) => handleChange(event, form, setform)}
                as='textarea'
                rows={10}
                placeholder='Descripcion'
              />
            </Form.Group>
          </Col>
        </Row>
        {form.images.length > 0 && (
          <Row className='mb-4'>
            {form.images.map((it, index) => (
              <Col key={`img-${index}`} xs lg='3'>
                <Image src={it} fluid />
              </Col>
            ))}
          </Row>
        )}
        <Row className='justify-content-md-center'>
          <Col>
            <Button
              variant='secondary'
              type='button'
              onClick={() => inputFile.current.click()}
              disabled={loading}
              className='float-start'>
              {'Subir imagen'}
            </Button>
            <Form.Control
              ref={inputFile}
              type='file'
              accept='image/*'
              name='imgs'
              className='d-none'
              onChange={(event) => uploadImg(event)}
            />
          </Col>
          <Col>
            <Button
              variant='primary'
              type='submit'
              disabled={loading}
              className='float-end'>
              {'Agregar Nuevo Proyecto'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}
