import { types } from '../types/types'

export const projectsReduce = (state = [], action) => {
  switch (action.type) {
    case types.projects:
      return action.payload

    default:
      return state
  }
}
