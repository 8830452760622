import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  Badge,
  Breadcrumb,
  Button,
  Carousel,
  Container,
  Image,
  Row,
} from 'react-bootstrap'

export const ProjectSingleDetails = () => {
  const { id } = useParams()
  const state = useSelector((state) => state)
  const project = state.projects?.find((project) => project?.id === id)
  const auth = state?.auth?.token && true

  return (
    <Container className='mb-5 animate__animated animate__fadeIn my-2'>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/projects' }}>
          Proyectos
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{project?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h3>{project?.name}</h3>
      <p>
        {project?.badges?.map((badge) => (
          <Badge bg='warning' text='dark' className='me-2' key={badge}>
            {badge}
          </Badge>
        ))}
      </p>
      <p>{project?.description}</p>
      <a
        href={project?.urlApp}
        target='_blank'
        rel='noreferrer noopener'
        className='btn btn-secondary text-start my-2'>
        {project?.urlClient}
      </a>
      <a
        href={project?.urlApp}
        target='_blank'
        rel='noreferrer noopener'
        className='btn btn-secondary text-start my-2'>
        {project?.urlApp}
      </a>
      {project?.images && (
        <Carousel>
          {project?.images?.map((img, i) => (
            <Carousel.Item key={`id_key${i}`}>
              <Image src={img} alt={project?.resume} fluid />
            </Carousel.Item>
          ))}
        </Carousel>
      )}
      {auth && (
        <Row>
          <Button
            as={Link}
            className='mt-4'
            variant='primary'
            to={`/admin/projects-ae?id=${project.id}&edit=true`}>
            Editar proyecto
          </Button>
        </Row>
      )}
    </Container>
  )
}
