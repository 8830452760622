import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { NavBar } from './ui/NavBar'
import '../index.scss'
import '../assets/iconos.js'
import { Braudin } from './components/Braudin'
import { Home } from './components/Home'
import { ProjectsList } from './components/ProjectsList'
import { PoliciesWork } from './components/PoliciesWork'
import { ProjectSingleDetails } from './components/ProjectSingleDetails'
import { Footer } from './components/Footer'
import { Login } from './components/Login'
import { Error404 } from './components/Error404'
import { auth } from '../firebase'
import { login } from '../actions/auth'
import { PrivateRouter } from './PrivateRouter'
import { ProyectoAdd } from './components/admin/ProyectoAdd'
import { Practices } from './components/Practices'
import { PracticesAdd } from './components/admin/PracticesAdd'
import { PracticesDetails } from './components/PracticesDetails'

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user?.accessToken) {
        dispatch(login(user?.accessToken, user))
      }
    })
  }, [dispatch])

  return (
    <Router>
      <NavBar />
      <Container fluid>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <Braudin />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/projects' element={<ProjectsList />} />
              <Route path='/projects/:id' element={<ProjectSingleDetails />} />
              <Route path='/practices' element={<Practices />} />
              <Route path='/practices/:id' element={<PracticesDetails />} />
              <Route
                path='/admin/*'
                element={
                  <PrivateRouter>
                    <Routes>
                      <Route path='projects-ae' element={<ProyectoAdd />} />
                      <Route path='practices-ae' element={<PracticesAdd />} />
                    </Routes>
                  </PrivateRouter>
                }
              />
              {/* <Route path='/publicaciones' element={<PublicacionesList />} /> */}
              {/* <Route
                  path='/publicaciones/:id'
                  element={<PublicacionDetails />}
                /> */}
              <Route path='/policies' element={<PoliciesWork />} />
              <Route path='/login' element={<Login />} />
              <Route path='*' element={<Error404 />} />
            </Routes>
            <Footer />
          </Col>
        </Row>
      </Container>
    </Router>
  )
}

export default App
